import request from '@/utils/request'

// 查询平台API列表
export function findApiInterfaces(params) {
  return request({
    url: `/api_interfaces`,
    method: 'get',
    params: params
  })
}

// 查询单个API表单值
export function findApiInterfaceForm(recordId) {
  return request({
    url: `/api_interfaces/${recordId}/form`,
    method: 'get'
  })
}

// 更新API
export function updateApiInterface(recordId, data) {
  return request({
    url: `/api_interfaces/${recordId}`,
    method: 'put',
    data
  })
}

// 平台API权限客户列表
export function findApiInterfaceAgents(recordId, params) {
  return request({
    url: `/api_interfaces/${recordId}/agents`,
    method: 'get',
    params: params
  })
}
