<template>
  <div>
    <search-api-interface @submit="submitSearch" />

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="agents_count" slot-scope="agents_count, record">
        <a
          v-if="agents_count > 0"
          @click="showAgentsModal(record.id)"
        >{{ agents_count }}</a>
        <span v-else>{{ agents_count }}</span>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.id)">编辑</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="showOperationHistoryModal(record.id)">操作历史</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-api-interface
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="ApiInterface"
      :visible.sync="isShowOperationHistoryModal"
    />

    <api-interface-associated-agents
      v-if="isShowAgentsModal"
      :visible.sync="isShowAgentsModal"
      :id="showingAgentsId"
    />

  </div>
</template>

<script>
import { findApiInterfaces } from '@/api/api_interface'
import Pagination from '@/components/Pagination/index'
import { hasPermission } from '@/utils/permission'
import SearchApiInterface from '@/views/api_interfaces/Search'

export default {
  name: 'ApiInterfaceList',
  components: {
    SearchApiInterface,
    Pagination,
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    ApiInterfaceAssociatedAgents: () => import('@/views/api_interfaces/associated_agents/index'),
    EditApiInterface: () => import('@/views/api_interfaces/Edit')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingId: 0, // 正在编辑的记录id
      isShowOperationHistoryModal: false, // 是否显示操作历史弹窗
      showingOperationHistoryId: 0, // 正在显示操作历史的记录id
      isShowAgentsModal: false, // 是否显示客户列表弹窗
      showingAgentsId: 0, // 正在显示客户列表的记录id
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '接口名称',
          width: 200,
          dataIndex: 'name'
        },
        {
          title: '接口类型',
          width: 100,
          dataIndex: 'api_type'
        },
        {
          title: '默认开通',
          width: 100,
          dataIndex: 'is_default'
        },
        {
          title: '开通客户数',
          width: 100,
          dataIndex: 'agents_count',
          scopedSlots: { customRender: 'agents_count' }
        },
        {
          title: '说明',
          width: 200,
          dataIndex: 'description'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return hasPermission('api_interfaces/edit')
    }
  },
  methods: {
    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    // 显示权限客户
    showAgentsModal(id) {
      this.showingAgentsId = id
      this.isShowAgentsModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findApiInterfaces(this.query).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>
